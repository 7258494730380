.tabs {
    .tabs--bar {
        list-style: none;
        display: flex;
        padding: 0;

        li {
            flex: 1;
            text-align: center;
            border-bottom: 1px solid $border-color;

            &:hover {
                border-bottom: 1px solid $bright-blue;
                cursor: pointer;
            }

            &.active {
                border-bottom: 1px solid $bright-purple;
                color: darken($font-color, 20%);
            }
        }
    }

    .tabs--panels {
        > div {
            display: none;
            padding: 15px 0;

            &.active {
                display: block;
            }
        }
    }
}
body #sb-site {
    background: $dark-bg;
}

.__columns {
    display: flex;

    .__column {
        flex: 1;

        &.right {
            text-align: right;
        }
    }

    .__primary {
        flex: 5;
    }
}

#color-divider {
    height: 5px;
    background-image: linear-gradient(90deg, $bright-blue, $bright-purple);
}

// Animation
.revealOnScroll {
    opacity: 0;
}

.revealOnScroll.animated {
    opacity: 1;
}

// generic blocks
.block {
    color: $white;

    div {
        @include vertical-align();
    }

    &.blue {
        background: $standard-blue;
    }

    &.green {
        background: $standard-green;
    }

    &.purple {
        background: $standard-purple;
    }
}

.center-content {
    .padding {
        text-align: center;
    }
}

// Social icons
.social-buttons {
    a {
        text-align: center;
    }

    .fa {
        display: inline-block;
        font-size: 0px;
        cursor: pointer;
        padding: 8px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        text-align: center;
        position: relative;
        z-index: 1;
        color: #fff;

        &:before {
            font-size: 26px;
            z-index: 2;
            position: relative;
        }

        &:after {
            opacity: 0;
            background: #00AAE2;
            pointer-events: none;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: 100%;
            content: '';
            transform: scale(0.2);
            transition: all .2s ease-in-out;
        }

        &.github:after {
            background: #0077BB;
        }

        &.chat:after {
            background: #728ADA;
        }

        &.dashboard:after, &.feed:after {
            background: #E55D2F;
        }

        &:hover:after {
            transform: scale(1);
            opacity: 1;
        }
    }

    .dashboard-avatar {
        display: block;
        height: 40px;
        width: 40px;
        padding: 5px;
        position: relative;

        img {
            width: 30px;
            height: 30px;
            border-radius: 15px;
            vertical-align: top;
            z-index: 1;

            &.account-svg {
                height: 24px;
                width: 24px;
                margin-top: 3px;
            }
        }

        &:before {
            z-index: -1;
            opacity: 0;
            background: #29BAA5;
            pointer-events: none;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: 100%;
            content: '';
            transform: scale(0.2);
            transition: all .2s ease-in-out;
        }

        &:hover:before {
            transform: scale(1);
            opacity: 1;
        }

        & ~ span {

        }
    }

}

// Search Wrapper
$iconSize: 35px;
$leading-margin: 1rem;

.search-wrapper {

    .input-wrapper {
        position: relative;

        &:after {
            display: block;
            background: url(../images/search.svg);
            width: $iconSize;
            height: $iconSize;
            position: absolute;
            top: 13px;
            left: 10px;
            content: '';
            opacity: 0.1;
        }
    }

    input, select {
        margin: 0;
        width: 100%;
        background: $white;
        border: 1px solid $light-grey;
        font-size: 25px;
        font-weight: 200;
        border-radius: 0;
    }

    input {
        padding-left: 50px;
        height: 60px;
    }

    i {
        display: none;
        position: absolute;
        top: 13px;
        right: 7px;
        bottom: 0;
        padding-right: 6px;
        font-size: 30px;
        color: #e1e1e1;
        line-height: 35px;
        cursor: pointer;
    }

}

// Content body
#content {
    position: relative;
    background: $light-bg;
    padding-bottom: 100px;

    #overlap {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: $standard-overlap;
        background: $dark-bg;
    }

    .container {
        background: $white;
        box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.05);
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    .padding {
        position: relative;
        padding: 5rem 8rem;
        z-index: 1;

        @include mq($from: tablet, $until: desktop) {
            padding: 5rem 4rem;
        }

        @include mq($from: mobile, $until: tablet) {
            padding: 5rem 2rem;
        }
    }
}

.container, .row {
    position: relative;
    z-index: 1;
}

// Tabs
.tab-nav {
    height: $standard-overlap;
    line-height: $standard-overlap;
    color: rgba($white, 0.3);
    font-size: 2rem;
    overflow: hidden;
    background: $standard-purple;
    text-align: center;

    .tab {
        display: block;
        float: left;
        position: relative;
        font-family: $title-font-family;
        font-weight: 300;
        // text-transform: uppercase;
        font-size: 1.6rem;
        border-right: 1px solid rgba(#fff, 0.2);

        &:last-child {
            border-right: 0;
        }

        &.active {
            background: $white;
            color: $nearly-black !important;

        }

        .count {
            display: inline;
            background: rgba(0, 0, 0, 0.1);
            margin-left: 10px;
            border-radius: 7px;
            height: 26px;
            line-height: 26px;
            font-size: 16px;
            padding: 2px 6px;
            vertical-align: inherit;
            font-weight: 500;
        }
    }

    a {
        color: rgba($white, 0.9);

        &:hover {
            color: $white;
        }
    }

    .badge {
        background: rgba(0, 0, 0, 0.1);
        margin-left: 10px;
        border-radius: 7px;
        height: 26px;
        line-height: 26px;
        font-size: 16px;
    }
}

#content.fade, #content.skeletons, #content.themes, #content.plugins {
    .container {
        background: $light-bg;
        box-shadow: none;

        .padding {
            background-image: linear-gradient(to bottom, $white 0%, $light-bg 100%);
        }

    }

    h1 {
        margin: 3rem 0 3rem;
    }
}

.version, .version span {
    text-transform: none;
}

.copy-to-clipboard {
    background-image: url(../images/clippy.svg);
    background-position: 50% 50%;
    background-size: 14px 14px;
    background-repeat: no-repeat;
    width: 25px;
    height: 25px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: -1px;
    color: darken($font-color, 10%);
    background-color: lighten(#FDF7E3, 1%);
    border: 1px solid darken(#FDF7E3, 5%);
    margin-left: -.4rem;
    cursor: pointer;
    border-radius: 0 2px 2px 0;

    &:hover {
        background-color: darken(#FDF7E3, 5%);
    }

    pre & {
        position: absolute;
        right: 4px;
        top: 4px;
        background-color: #eee;
        border-color: #ddd;
        border-radius: 2px;

        &:hover {
            background-color: #d9d9d9;
        }
    }
}

// Author
.post-author {

    img {
        max-width: 50px;
        max-height: 50px;
        border-radius: 100%;
        vertical-align: middle;
        margin: 0 5px 0;
    }
}

// Error page
#error {
    position: relative;
    height: 800px;
    overflow: hidden;
    text-align: center;


    h1 {
        font-size: 9rem;
        color: $white;
        margin-top: 150px;
    }

    p {
        color: lighten($light-purple, 10%);
        font-size: 3rem;
        font-weight: 200;
        display: block;
        margin: 1.5rem 8rem;
    }

    @include mq($from: tablet, $until: desktop) {
        h1 {
            font-size: 6rem;
            margin-top: 100px;
        }
        p {
            font-size: 2.2rem;
            margin: 1rem 5rem;
        }
    }

    @include mq($from: mobile, $until: tablet) {
        h1 {
            font-size: 4rem;
            margin-top: 50px;
        }
        p {
            font-size: 1.8rem;
            margin: 1rem 3rem;
        }
    }
}

// Team display
.core-members {
    display: table;
    width: 100%;
    margin-bottom: 5rem;

    > div {
        display: table-row;

        > div {
            display: table-cell;
            text-align: center;

            img {
                width: 50%;
                border-radius: 5px;
            }

            h6 {
                margin: 0;
                @include mq($from: mobile, $until: tablet) {
                    font-size: 1.5rem;
                }
            }

            p {
                margin: 0;
            }
        }
    }
}

.translation-members {
    ul {
        padding: 0;
    }

    li {
        width: 49%;
        display: inline-block;

        &:first-child {
            width: 100%;
            text-align: center;
            margin-bottom: 15px;

            @include mq($from: mobile, $until: tablet) {
                text-align: left;
            }
        }

        @include mq($from: mobile, $until: tablet) {
            width: 100%;
        }
    }
}

section#banner {
    background: $standard-purple;
    color: rgba(#fff, 0.8);
    text-align: center;
    font-size: 1.5rem;
    padding: 1rem;
    position: relative;

    p {
        margin: 0;
    }

    a {
        color: rgba(#fff, 0.9);
        border-bottom: 1px solid rgba(#fff, 0.8);
    }

    .oclogo, img {
        height: 2rem;
        vertical-align: middle;
    }
}

// Slack Invite
.slack-chat {
    .container {
        .padding {
            text-align: center;

            .alert {
                max-width: 550px;
                margin: 3rem auto;
                border-radius: 3px;

                p {
                    color: #fff;

                    &:before {
                        font-size: 3.5rem;
                        left: 20px;
                    }
                }

                &.red {
                    background-color: $standard-tomato;
                }
            }

            h1 {
                margin-top: -4rem;
            }

            strong {
                color: $standard-purple;

                em {
                    font-style: normal;
                    color: $standard-tomato;
                }
            }


            .form-label {
                display: none;
            }

            .form-field {
                float: left;

                input {
                    width: 250px;
                    height: 50px;
                    border-radius: 5px;
                    margin-right: 1rem;
                }
            }

            #slack-form {

                max-width: 500px;
                margin: 0 auto 5rem;

                content: "";
                display: table;
                clear: both;
            }

            .buttons {
                float: left;

                .button {
                    margin: 0;
                }
            }
        }
    }
}

#slack-form {
    .form-field {
        margin-right: -6rem;
    }

    [name="data[email]"] {
        width: 300px;
        border-radius: 25px;
        border-color: $bright-blue;
    }

    .button.dots-are-spinning, .button.has-error, .button.succeeded {
        padding: 0 13px;
        width: 50px;
        transition: all 0.2s ease-in-out;
    }

    .button.dots-are-spinning {
        &, &:before, &:hover:before {
            background-color: $standard-purple !important;
            cursor: default;
        }
    }

    .button.has-error {
        &, &:before, &:hover:before {
            background-color: $standard-tomato !important;
            cursor: pointer;
        }
    }

    .button.succeeded {
        &, &:before, &:hover:before {
            background-color: $standard-green !important;
            cursor: default;
        }
    }

    .dots-spinner {
        width: 25px;
        height: 25px;
        position: relative;
        text-align: center;
        display: inline-block;

        animation: sk-rotate 2.0s infinite linear;

        .dot1, .dot2 {
            width: 60%;
            height: 60%;
            display: inline-block;
            position: absolute;
            top: 0;
            background-color: #fff;
            border-radius: 100%;

            animation: sk-bounce 2.0s infinite ease-in-out;
        }

        .dot2 {
            top: auto;
            bottom: 0;

            animation-delay: -1.0s;
        }
    }
}

#discord {
    $discord-color: #7289DA;
    max-width: 800px;
    margin: 0 auto;

    .discord-logo {
        max-width: 500px;
        margin: 0 auto;
        display: block;
    }

    .discord-text {
        margin: 0 auto;
    }

    .discord-members-list {
        margin: 4rem auto;
        text-align: center;
        background: #F6F4F8;
        padding: 4rem .5rem 0;
        border-radius: 3px;
        line-height: 1.3;
    }

    .discord-members {
        display: inline;
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .discord-members li {
        display: inline-block;
        padding: 0;
        font-size: 80%;
        font-weight: 500;
        margin: 0 5px 0 0;

        // &.gravitator {
        //   color: #00BF9A;
        // }

        &.pro {
            color: #00BFF9;
        }

        &.moderator {
            color: #FB3A2F;
        }

        &.docs-team {
            color: #BD0057;
        }

        // &.admin {
        //   color: #CE7800;
        // }

        &.grav-core-team {
            color: #8D47A6;
        }
    }

    .discord-members img {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        vertical-align: middle;
    }

    .button.button-primary {
        background-color: $discord-color;
        border-color: $discord-color;

        &:hover {
            background-color: darken($discord-color, 5%);
        }

        img {
            height: 26px;
            vertical-align: middle;
        }
    }
}

.selectize-dropdown, .selectize-input, .selectize-input input {
    line-height: inherit !important;
}

#slack-notice {
    display: block;
    clear: both;
    margin-bottom: 2rem;

    &.red {
        color: $standard-tomato;
    }

    &.green {
        color: $standard-jungle;
    }

    & + form [name="data[email]"] {
        border-color: $bright-blue;
    }

    &.red + form [name="data[email]"] {
        border-color: $standard-tomato;
    }

    &.green + form [name="data[email]"] {
        border-color: $standard-green;
    }
}

@keyframes sk-rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes sk-bounce {
    0%, 100% {
        transform: scale(0.0);
    }
    50% {
        transform: scale(1.0);
    }
}



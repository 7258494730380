// background colors
.blue {
    background-color: $standard-blue !important;
}

.purple {
    background-color: $standard-purple !important;
}

.green {
    background-color: $standard-green !important;
}

.jungle {
    background-color: $standard-jungle !important;
}

.plum {
    background-color: $standard-plum !important;
}

.tomato {
    background-color: $standard-tomato !important;
}

.gold {
    background-color: $standard-gold !important;
}

.yellow {
    background-color: $standard-yellow !important;
}

.overcast {
    background-color: $standard-overcast !important;
}

.navy {
    background-color: $standard-navy !important;
}

.royalblue {
    background-color: $standard-royalblue !important;
}

.darken_10 {
    background-color: rgba(#000, 0.1);
}

.darken_20 {
    background-color: rgba(#000, 0.2);
}

.pad-top {
    margin-top: 5rem;
}

.pad-bottom {
    margin-bottom: 5rem;
}

.vertical-middle {
    vertical-align: middle;
}

// images
img {
    max-width: 100%;
    border: 0;

    &.center {
        margin: 2rem auto;
        display: block;
    }

    &.shadow {
        margin: 4rem auto;
        display: block;
        box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
    }
}

.retina-img {
    text-align: center;

    img {
        width: 50%;
    }
}

h1 {
    margin: 3rem 0 7rem;
    font-size: 6rem;
    // text-transform: uppercase;
    color: $nearly-black;

    @include mq($from: tablet, $until: desktop) {
        font-size: 5rem;
        margin: 3rem 0 5rem;
    }

    @include mq($from: mobile, $until: tablet) {
        font-size: 3.2rem;
        margin: 2rem 0 4rem;
    }
}

#content.default {
    h1 {
        text-align: center;

    }

    h1 + h2 {
        text-align: center;
        font-family: $default-font-family;
        font-size: 2.5rem;
        margin-top: -7rem;
        margin-bottom: 4rem;

        @include mq($from: tablet, $until: desktop) {
            margin-top: -5.5rem;
            font-size: 2rem;
        }

        @include mq($from: mobile, $until: tablet) {
            margin-top: -4rem;
            font-size: 1.8rem;
        }
    }
}

// common subheader
.subheader {
    font-family: $default-font-family;
    font-size: 2.2rem;
    margin: 0;
    font-weight: 500;
    letter-spacing: -1px;

    @include mq($from: mobile, $until: tablet) {
        font-size: 2.0rem;
    }
}

.bigger {
    p {
        font-size: 2.5rem;
        font-weight: 200;

        @include mq($from: tablet, $until: desktop) {
            font-size: 2.2rem;
        }

        @include mq($from: mobile, $until: tablet) {
            font-size: 2.0rem;
        }
    }
}

.padding {
    padding: 5rem 0;

    @include mq($from: mobile, $until: tablet) {
        padding: 4rem 0;
    }

    &.more {
        padding: 5rem 8rem;

        @include mq($from: mobile, $until: tablet) {
            padding: 4rem 3rem;
        }
    }

}

.align-right, .right {
    text-align: right;
}

.align-left, .left {
    text-align: left;
}

.align-center, .center {
    text-align: center;
}


// Blockquote
blockquote {
    border-left: 5px solid lighten($font-color, 45%);
    padding-left: 15px;
    margin: 3rem 0;

    p {
        color: #999;
    }

    cite {
        display: block;
        text-align: right;
        color: #666;
        font-size: 1.2rem;
    }
}

// Badges
.badge {
    display: inline-block;
    border-radius: 5px;
    font-weight: 500;
    padding: 0 5px;
}

// NOTES!!!!
blockquote {
    position: relative;
}

blockquote blockquote {
    position: static;
}

blockquote > blockquote > blockquote {

    margin: 0;

    p {
        padding: 15px;
        display: block;
        margin-top: 0rem;
        margin-bottom: 0rem;
        color: #666;

        &:first-child {
            &:before {
                position: absolute;
                top: 2px;
                color: $white;
                font-weight: normal;
                font-family: ForkAwesome;
                content: '';
                left: 10px;
            }

            &:after {
                position: absolute;
                top: 2px;
                color: $white;
                left: 40px;
                content: 'Info';
            }
        }
    }

    > p {
        // Yellow
        margin-left: -60px;
        border-top: 30px solid $note-yellow-title;
        background: $note-yellow-body;
    }

    > blockquote > p {
        // Red
        margin-left: -80px;
        border-top: 30px solid $note-red-title;
        background: $note-red-body;

        &:first-child:after {
            content: 'Warning';
        }
    }

    > blockquote > blockquote > p {
        // Blue
        margin-left: -100px;
        border-top: 30px solid $note-blue-title;
        background: $note-blue-body;

        &:first-child:after {
            content: 'Note';
        }
    }

    > blockquote > blockquote > blockquote > p {
        // Green
        margin-left: -120px;
        border-top: 30px solid $note-green-title;
        background: $note-green-body;

        &:first-child:after {
            content: 'Tip';
        }
    }
}

div.notices {
    margin: 3rem 0;
    position: relative;

    > p:first-child, > div:first-child {
        padding: 15px;
        display: block;
        margin-top: 0rem;
        margin-bottom: 0rem;
        color: #666;

        &:before {
            position: absolute;
            top: 2px;
            color: $white;
            font-family: ForkAwesome;
            content: '';
            left: 10px;
        }

        &:after {
            position: absolute;
            top: 2px;
            color: $white;
            left: 3.5rem;
        }

    }

    &.info {
        > p:first-child, > div:first-child {
            border-top: 30px solid #F0B37E;
            background: #FFF2DB;

            &:first-child:after {
                content: 'Info';
            }
        }
    }

    &.warning {
        > p:first-child, > div:first-child {
            border-top: 30px solid rgba(#D9534F, 0.8);
            background: #FAE2E2;

            &:first-child:after {
                content: 'Warning';
            }
        }
    }

    &.note, &.default {
        > p:first-child, > div:first-child {
            border-top: 30px solid #6AB0DE;
            background: #E7F2FA;

            &:first-child:after {
                content: 'Note';
            }
        }
    }

    &.tip {
        > p:first-child, > div:first-child {
            border-top: 30px solid rgba(#5CB85C, 0.8);
            background: #E6F9E6;

            &:first-child:after {
                content: 'Tip';
            }
        }
    }
}

// Standard messages
#messages {
    margin: 0;

    div.notices {
        margin: 0;

        > div:first-child {
            background: none;
            border: 0;
            color: $white;

            &:before, &:after {
                display: none;
            }

            p {
                margin: 0;
            }

        }

        &.default {
            background-color: $standard-green !important;
        }

        &.info {
            background-color: $standard-purple !important;
        }

        &.error {
            background-color: $standard-tomato !important;
        }
    }
}

// Form messages
#form-messages {
    margin: 0 -15px;

    .notices {

        &.error {
            p {
                background-color: $standard-tomato;
                color: $white;
            }
        }

        &.success {
            p {
                background-color: $standard-green;
                color: $white;
            }
        }


        > p:first-child {
            &:before {
                display: none;
            }

        }
    }
}

mark {
    background-color: #FBF0CB;
}

div.prism-wrapper {
    margin-top: 2.5rem;

    pre[class*="language-"] {
        margin-top: 0;
    }
}

pre[class*="language-"] {
    margin: 2.5rem 0;

    .line-highlight {
        background-image: linear-gradient(to right, rgba($highlight-color, 0.2) 70%, rgba($highlight-color, 0));

        &:before {
            background-color: darken($highlight-color, 30%);
            //color: #999;
            box-shadow: none;
            top: 0.15rem;
        }
    }

    ::selection {
        background: $selection-color;
    }
}

// Bright notice
.bright {
    font-size: 90%;
    padding: 0 4px;
    background: $standard-purple;
    color: #ddd;
    border-radius: 4px;
    font-weight: 500;
}

// Progress Bars
h5 + .progressBar {
    margin-bottom: 1rem;
    margin-top: -1rem;
}

.progressBar {
    width: 100%;
    height: 4px;
    background-color: $light-grey;
}

.progressBar div {
    height: 100%;
    color: #fff;
    width: 0;
    background-color: $standard-green;
}

// Stars
.star-rating-container {
    white-space: nowrap;
}

// Changelog
body .changelog {

    h3, h4 {
        text-align: center;
    }

    h3 {
        margin: 1.2rem 0 0 0;
        font-size: 4rem;
        line-height: 1;
        font-weight: bold;
    }

    h3 + h4 {
        font-size: 2rem;
        margin: 0 0 1.2rem 0;
        color: #666;
    }

    ol {
        list-style: none;
        padding: 0;
        margin: 0 0 3rem 0;

        a[href='#new'] {
            & + ul > li {
                &:before {
                    background-color: #2ecc71;
                    content: 'New';
                }
            }
        }

        a[href='#improved'] {
            & + ul > li {
                &:before {
                    background-color: #3498db;
                    //color: #333;
                    content: 'Improved';
                }
            }
        }

        a[href='#bugfix'] {
            & + ul > li {
                &:before {
                    background-color: #e74c3c;
                    content: 'Bugfix';
                }
            }
        }
    }

    ul {
        list-style: none;
        padding: 0;

        li {
            margin-bottom: 1rem;
            padding-left: 9rem;

            &:before {
                margin-left: -9rem;
                display: inline-block;
                border-radius: 2px;
                color: #fff;
                margin-right: 1rem;
                text-align: center;
                width: 8rem;
                font-size: 1.3rem;
                padding: 2px 0;
                font-style: normal;
            }
        }
    }

}

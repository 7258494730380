$form-border-color: $border-color;
$form-active-color: #000;

.form-field {
    display: flex;

    label {
        font-weight: normal;
        color: $dark-grey;
    }

    .form-textarea-wrapper {
        display: flex;
    }

    textarea {
        height: auto;
    }

    .form-label {
        flex: 1;
        padding-top: 5px;
    }

    .form-data {
        flex: 2;
        margin-bottom: 1.5rem;

        input, textarea, select, [data-grav-array-type="container"] {
            width: 100%;
            margin: 0;

            &.x-small {
                width: 15%;
                min-width: 100px
            }

            &.small {
                width: 25%;
                min-width: 150px
            }

            &.medium {
                width: 50%;
                min-width: 300px;
            }

            &.large {
                width: 75%;
            }

        }
    }
}

.form-group.has-errors {
    background: rgba(255, 0, 0, 0.05);
    border: 1px solid rgba(255, 0, 0, 0.2);
    border-radius: 3px;
    margin: 0 -5px;
    padding: 0 5px;
}

.form-errors {
    color: #b52b27;
}

.form-errors p {
    margin: 0;
}

.form-input-file {

    input {
        display: none;
    }

    .dz-default.dz-message {
        position: absolute;
        text-align: center;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        margin: 0;
    }

    &.dropzone {
        position: relative;
        min-height: 70px;
        border-radius: 3px;
        margin-bottom: .85rem;
        border: 2px dashed #ccc;
        color: #aaa;
        padding: 0.5rem;

        .dz-preview {
            margin: 0.5rem;

            &:hover {
                z-index: 2;
            }

            .dz-error-message {
                min-width: 140px;
                width: auto;
            }

            .dz-image,
            &.dz-file-preview .dz-image {
                border-radius: 3px;
                z-index: 1;
            }
        }
    }
}

// Prepend/Append
.form-input-addon-wrapper {
    display: table-row;

    .form-input-addon {
        display: table-cell;
        min-width: 43px;
        padding: 0 10px;
        text-align: center;
        border: 1px solid $form-border-color;
    }

    input {
        height: 35px;
        display: table-cell;
    }

    .form-input-prepend {
        border-radius: 4px 0 0 4px;
        border-right-width: 0 !important;

    }

    .form-input-append {
        border-radius: 0 4px 4px 0;
        border-left-width: 0 !important;
    }

    input:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    input:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}

// New JS powered tabs
.form-tabs {

    .tabs-nav {
        display: flex;
        padding-top: 1px;

        margin-bottom: -1px;

        a {
            flex: 1;
            transition: color 0.5s ease, background 0.5s ease;
            cursor: pointer;
            text-align: center;
            padding: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 1px solid $form-border-color;

            span {
                color: $font-color;
            }

            &:hover {
                border-bottom: 1px solid $bright-blue;
                cursor: pointer;
            }

            &.active {
                border-bottom: 1px solid $bright-purple;

                span {
                    color: darken($font-color, 20%);
                }
            }
        }

        span {
            display: inline-block;
            line-height: 1.1;
        }

    }

    &.subtle .tabs-nav {
        margin-right: 0 !important;
    }

    .tabs-content {

        .tab__content {
            display: none;
            padding-top: 5rem;

            &.active {
                display: block;
            }
        }
    }
}

// Checkboxes
.checkboxes {
    display: inline-block;

    label {
        display: inline;
        cursor: pointer;
        position: relative;
        padding: 0 0 0 20px;
        margin-right: 15px;
    }

    label:before {
        content: "";
        display: inline-block;
        width: 20px;
        height: 20px;
        left: 0;
        margin-top: 0;
        margin-right: 10px;
        position: absolute;
        border-radius: 3px;

        border: 1px solid $form-border-color;

    }

    input[type=checkbox] {
        display: none;
    }

    input[type=checkbox]:checked + label:before {
        content: "\f00c";
        font-family: "ForkAwesome";
        font-size: 18px;
        line-height: 1;
        text-align: center;
    }

    &.toggleable label {
        margin-right: 0;
    }
}

// Toggleable
.form-field-toggleable {
    .checkboxes.toggleable {
        margin-right: 5px;
        vertical-align: middle;
    }

    .checkboxes + label {
        display: inline-block;
    }
}

// Toggles
.switch-toggle {
    display: inline-flex;
    overflow: hidden;
    border-radius: 3px;
    line-height: 35px;
    border: 1px solid $form-border-color;

    input[type=radio] {
        position: absolute;
        visibility: hidden;
        display: none;
    }

    label {
        display: inline-block;
        cursor: pointer;
        padding: 0 15px;
        margin: 0;
        white-space: nowrap;
        color: inherit;
        transition: background-color 0.5s ease;
    }

    input.highlight:checked + label {
        background: $primary-color;
        color: #fff;
    }

    input:checked + label {
        background: $standard-overcast;
        color: #fff;

    }
}

// Array
[data-grav-array-type="container"] {
    //margin-bottom: 1.5rem;
}

[data-grav-field="array"] .form-row {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
}

[data-grav-field="array"] .form-row > input,
[data-grav-field="array"] .form-row > textarea {
    margin: 0 0.5rem;
    display: inline-block;
}

.releases-field {
    [data-grav-array-type="key"] {
        width: 130px !important;
    }
}
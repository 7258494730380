.blog {

    .spinning-wheel {
        display: block;
        width: 100%;
        text-align: center;
        font-size: 50px;
        color: #ccc;
    }

    .search-wrapper {
        a {
            color: $light-grey;
        }

        .fa {
            display: block;
        }
    }
}

.post {

    h1, h2, h3, h4, h5, h6 {
        margin-top: 3.5rem;
    }

    h1 {
        font-size: 4.5rem;
        margin-bottom: 2rem;
        @include mq($from: mobile, $until: tablet) {
            font-size: 3rem;
        }
    }

    h2 {
        font-size: 3.5rem;
        @include mq($from: mobile, $until: tablet) {
            font-size: 2rem;
        }

        &.subtitle {
            font-size: 2.5rem;
            font-weight: 300;
            color: $font-color;
            margin-top: -20px;

            @include mq($from: mobile, $until: tablet) {
                font-size: 2rem;
            }
        }
    }

    h3 {
        font-size: 2.5rem;
    }

    ul {
        margin-bottom: 3rem;
    }

    #post-nav {
        .back {
            display: block;
            float: left;
            width: 20%;
            height: $standard-overlap;
            border-right: 1px solid rgba(#fff, 0.2);
        }

        .last {
            border: 0 !important;
        }

        .next, .prev, .first, .last {
            height: $standard-overlap;
            text-align: center;
            display: block;
            float: left;
            width: 20%;
            border-right: 1px solid rgba(#fff, 0.2);
            transition: all 0.3s;
            font-size: 2.5rem;

            &:hover {
                background: rgba(0, 0, 0, 0.1);
                color: $white;
                font-size: 3rem;
            }
        }
    }

    $post-info-height: 50px;

    #post-info {
        margin-bottom: 6rem;
        line-height: $post-info-height;
    }

    @include mq($from: mobile, $until: desktop) {
        .post-extra {
            display: block;
            margin-left: 65px;
            margin-top: -12px;
            line-height: 1.5;
        }

        #post-info {
            margin-bottom: 4rem;
            text-align: left;
        }
    }

    .post-tags {
        margin-right: 20px;
    }

    .post-image-header {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1;
        height: 400px;
        background-size: cover;
        background-position: center;
    }

    .post-text-header {
        z-index: 2;
        position: relative;
    }

    &.full {
        .post-text-header {
            margin-top: -5rem;
            padding: 5rem 0 0;
            text-align: center;
            height: 475px;
            color: rgba($white, 0.9);

            #post-info {
                position: absolute;
                right: 0;
                bottom: 50px;
                left: 0;
            }

            a {
                color: $white;
            }

            h1 {
                margin-top: 70px;
                color: $white;
                @include mq($from: mobile, $until: desktop) {
                    margin-top: 20px;
                }
            }

            h2 {
                color: rgba($white, 0.7);
            }
        }
    }
}

#disqus_thread {
    margin-top: 8rem;
}

#related {
    background: $light-bg;

    h2 {
        text-align: center;
    }
}

.item-cards {
    .next {
        display: none;
    }
}

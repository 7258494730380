$menu-padding: 0.9rem;

ul.navigation {
    font-family: $title-font-family;
    font-weight: 400;
    // text-transform: uppercase;
    font-size: 1.6rem;

    @include mq($from: tablet, $until: desktop) {
        font-size: 1.35rem;
    }

    @include mq($from: mobile, $until: tablet) {
        font-size: 1.2rem;
        a {
            padding: 0 $menu-padding/2;
        }
    }

    li {
        position: relative;

        &.active a, a.active {
            color: $white;
        }

        a:hover {
            color: darken($white, 20%) !important;
        }
    }

    a {
        padding: 0 $menu-padding;
        display: inline-block;
        line-height: 3rem;
        height: 3rem;
        color: $light-purple;
        text-shadow: none;
        -webkit-transition: color 0.3s;
        -moz-transition: color 0.3s;
        transition: color 0.3s;

        @include mq($from: wide) {
            padding: 0 $menu-padding * 2;
        }
    }
}

#sb-site {
    ul.navigation {

        a::before,
        a::after {
            position: absolute;
            left: $menu-padding;
            right: $menu-padding;
            height: 2px;
            background: darken($white, 20%) !important;;
            content: '';
            opacity: 0;
            transition: opacity 0.3s, transform 0.3s;
            transform: translateY(-10px);
        }

        a::before {
            top: 0;
            -webkit-transform: translateY(-10px);
            -moz-transform: translateY(-10px);
            transform: translateY(-10px);
        }

        a::after {
            bottom: 0;
            transform: translateY(10px);
        }

        a:hover {
            color: darken($white, 20%) !important;;
        }

        a:hover::before,
        a:hover::after {
            opacity: 1;
            transform: translateY(0px);
        }
    }
}

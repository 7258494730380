
.owl-theme {
    // Styling Next and Prev buttons

    .owl-item {
        > div {
            padding: 0 5rem;
            position: relative;
            z-index: 1;

            @include mq($from: mobile, $until: tablet) {
                padding: 0 1rem;
            }

        }

        p {
            position: absolute;
            left: 0;
            right: 0;
            z-index: 2;

            @include mq($from: mobile, $until: tablet) {
                display: none;
            }
        }
    }


    .owl-nav {
        position: absolute;
        top: 27%;
        width: 100%;
        text-align: center;
        -webkit-tap-highlight-color: transparent;

        @include mq($from: mobile, $until: desktop) {
            display: none;
        }

        [class*='owl-'] {
            color: $nav-color;
            font-family: 'ForkAwesome';
            font-size: $nav-font-size;
            margin: $nav-margin;
            padding: $nav-padding;
            display: inline-block;
            cursor: pointer;
            position: absolute;

            &:hover {
                color: $nav-color-hover;
                text-decoration: none;
            }
        }

        .disabled {
            color: rgba($nav-color, 0.2) !important;
            cursor: default !important;
        }

        .owl-prev {
            left: 2rem;
        }

        .owl-next {
            right: 2rem;
        }
    }

    // Styling dots
    .owl-nav.disabled + .owl-dots {
        margin-top: 10px;
    }

    .owl-dots {
        text-align: center;
        -webkit-tap-highlight-color: transparent;

        .owl-dot {
            display: inline-block;
            zoom: 1;
            *display: inline;

            span {
                width: $dot-width;
                height: $dot-height;
                margin: $dot-margin;
                background: $dot-background;
                display: block;
                -webkit-backface-visibility: visible;
                @include transition(opacity, 200ms, ease);
                @include rounded($dot-rounded);
            }

            &.active,
            &:hover {
                span {
                    background: $dot-background-active;
                }
            }
        }
    }
}

#modular-people-saying .owl-dots {
    margin-top: 5rem;

    .owl-dot {
        span {
            background: darken($light-purple, 40%);
        }

        &.active,
        &:hover {
            span {
                background: darken($light-purple, 10%);
            }
        }
    }
}

.features {
    #content {
        h2 {
            float: left;

            @include mq($from: mobile, $until: tablet) {
                float: none;
            }
        }

        ul {
            @extend .flexbox;
            text-align: left;
            margin-left: 20%;
            width: auto;
            margin-right: -2rem;

            @include mq($from: mobile, $until: tablet) {
                margin-left: 0;
                margin-right: 0;
            }
        }

        li {
            @extend .flexbox-item;
            width: 50%;
            padding-right: 2rem;

            p {
                margin-bottom: 1.5rem;
            }

            @include mq($from: mobile, $until: desktop) {
                width: 100%;
                padding-right: 0;
            }
        }

        h2 {
            font-size: 3rem;
        }

        h3 {
            @extend .subheader;
        }
    }
}

.likes-ratings-container.readonly {
    .like-up {
        background-color: #888;
    }

    .like-down {
        background-color: #aaa;
    }
}

.resources {

    h3 {
        margin-top: 70px;
    }

    .changelog {

        h3 {
            font-size: 5rem;
        }

        h3, h4 {
            text-align: left
        }
    }

    .dropdown-wrapper {
        position: relative;
        display: inline-block;

        &:before {
            font-family: ForkAwesome;
            content: "\f078";
            position: absolute;
            right: 5px;
            top: 5px;
            z-index: -1;
        }

        select {
            padding-right: 30px;
            margin: 0;
            background: transparent;
            border: none;
            font-weight: bold;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
        }
    }

    .resource-links {
        list-style: none;
        padding: 0;

        li {
            display: inline-block;
            white-space: nowrap;
            margin-right: 20px;
        }
    }

    .resource-list {
        line-height: 1.2;
        font-size: 90%;
        width: 100%;

        tr {
            border-bottom: 1px solid lighten($border-color, 2%);
        }

        td, th {
            border-bottom: 0;
        }

        th {
            font-weight: 300;
            background: inherit;
            color: lighten($font-color, 15%);
        }

        td {
            color: darken($font-color, 15%);
        }
    }

    .resource-header {
        .padding {
            padding-top: 0 !important;
        }
    }

    .release-chooser {
        white-space: nowrap;
        margin-bottom: 1.5rem;

    }

    .stable {
        .show-testing {
            display: none;
        }
    }

    .testing-box {
        background: #f6f4f8;
        border-radius: 6px;
        padding: 2rem;

        .button.button-solid {
            background: #888;
        }
    }

    .testing {
        .show-stable {
            display: none;
        }
    }

    .approved-team {
        color: $standard-blue;
    }

    .controls {
        .prev-button, .next-button {
            height: 60px;
            width: 60px;
        }
    }

    .badge {
        display: block;
        text-align: center;
        font-size: 1.2rem;
        line-height: 1.6;
        color: rgba(#fff, 0.9);
        text-transform: uppercase;
        vertical-align: middle;

        &.stable {
            background-color: #333;
            display: inline-block;
            text-transform: none;
        }

        &.testing {
            background-color: #666;
            display: inline-block;
            text-transform: none;
        }

        &.badge-free {
            background: $standard-green;
            color: #fff;
        }

        &.badge-premium {
            background: $standard-tomato;
            color: rgba(#fff, 0.9);
        }
    }

    .resource-image {
        display: inline-block;
        vertical-align: middle;


        > div {
            width: 64px;
            height: 64px;
            text-align: center;
            color: rgba(#fff, 0.6);
            border-radius: 5px;

            i.fa {
                line-height: 64px;
                font-size: 48px;
                vertical-align: top;
            }
        }

        table & {
            > div {
                width: 32px;
                height: 32px;

                i.fa {
                    line-height: 32px;
                    font-size: 20px;
                }
            }
        }
    }

    .resource-details {

        .edit-button {
            font-size: 50%;
            vertical-align: middle;
        }

        .resource-title {
            display: flex;
            max-width: 80%;
            margin: 0 auto 4rem;

            align-items: baseline;
            justify-content: center;

            h1 {
                line-height: 1;
                margin-bottom: 0;
                margin: 0.5rem 0 !important;
            }

            p {
                margin: 0;
            }

            .badge {
                display: inline-block;
                width: 64px;
            }

            .resource-image {
                margin-right: 15px;

                > div {
                    height: 96px;
                    width: 96px;

                    > i.fa {
                        font-size: 64px;
                        line-height: 96px;
                    }
                }

            }

        }

        > .row {
            border-top: 1px solid $border-color;

            display: flex;
            flex-direction: row-reverse;

            > .columns {
                padding-top: 3.5rem;
                margin: 0;
            }
        }

        .sidebar {
            border-left: 1px solid $border-color;
            padding-left: 2rem;
            width: 25%;

            .likes-ratings-container {
                justify-content: center;
                margin: 0 0 1rem;
                font-size: 20px;

                .like {
                    padding: 0.5rem 1.5rem;
                    flex: 1;
                }
            }
        }

        .mainbody {
            padding-right: 2rem;
            width: 75%;

            .tabs > ul {
                margin-top: 0;
            }

            .image-preview {
                > img {
                    width: 90%;
                    margin: 0 auto;
                    border-radius: 3px;
                    display: block;
                    box-shadow: 0 5px 50px rgba(#000, 0.3);
                }

            }
        }

        @include mq($from: mobile, $until: desktop) {

            > .row {
                flex-direction: column;
            }

            .sidebar, .mainbody {
                width: 100%;
                border: 0;
                padding: 0;
            }
        }
    }

    .download-buttons {
        margin: 0;
    }

    .version {
        margin-top: -1rem;
    }

    .tab-nav {
        .tab {
            width: 25%;
        }
    }

    .item-cards {
        margin-bottom: 3rem;
    }

    .download-versions {
        text-align: center;

        a.button {
            padding-right: 0;
            padding-left: 2rem;
            position: relative;

            select {
                background: transparent;
                margin: 0 0 0 10px;
                padding: 0 40px 0 1.5rem;
                border: 0;
                border-left: 2px solid #000;
                border-radius: 0 25px 25px 0;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                line-height: 46px;
                height: 46px;
                vertical-align: top;
                cursor: pointer;
                font-weight: normal;


            }

            //@include mq($from:mobile, $until: desktop) {
            //  padding: 0 15px;
            //
            //  select {
            //    padding: 0 15px 0 10px;
            //    margin-left: 0;
            //  }
            //
            //  &:before {
            //    right: 10px;
            //  }
            //}


            &:hover {
                border-color: $standard-purple;
                color: $standard-purple;

                select {
                    border-color: $standard-purple;
                }
            }
        }

        .dropdown-wrapper {

            &:before {
                right: 15px;
                top: 0px;
            }
        }
    }

    .showall {
        float: right;
        display: inline-block;
        line-height: 4rem;
    }

    // Cards
    .card__title {
        line-height: 1.1;

        table {
            margin: 0;
            width: 100%;

            td {
                padding: 0;
                border: 0;

                &:first-child {
                    padding-right: 15px;
                }

                &:nth-child(2) {
                    width: 100%;
                }
            }
        }

        .second-row td {
            padding-top: 5px;
        }

        .title {
            display: block;
            line-height: 1;
        }

        .likes-ratings-container {
            margin: 0;
            font-size: 1.2rem;
            line-height: 14px;

            .like {
                svg {
                    width: 13px;
                }
            }
        }

        .__column.right {
            .likes-ratings-container {
                margin-top: 5px;
            }
        }

        .author, .release-type {
            display: block;
            font-weight: 300;
            color: #999;
            font-size: 1.4rem;
            margin-top: 3px;
        }

        > div:first-child {
            padding-right: 15px;
        }
    }

    .card__footer {
        display: flex;

        .card__views {
            margin-left: 15px;
        }

        .card__time {
            line-height: inherit;
            text-align: right;
        }

        span {
            flex: 1;
        }
    }

    .card__center {
        text-align: center;
    }

    .card__description {
        max-height: 120px;
        overflow: hidden;

        p {
            margin-top: 5px;
        }

    }

    .card_thumb {
        background: $white;
        border: 1px solid $light-grey;
    }

    .card__tags {
        position: absolute;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
        float: none;
        margin: 0;
        z-index: 2;
        font-weight: 500;
    }

    .card__header {
        margin: 0 auto;
        font-size: 10rem;
        color: rgba($white, 0.7);
    }

    .card__links {
        margin-top: -10px;
    }

    .card__subtitle {
        margin: 10px 0;

        code {
            font-size: 16px;
        }

        .copy-to-clipboard {
            height: 23px; // code font-size * 2 + 1px border
        }
    }

    .card__body {
        overflow: visible;
    }

    .plugins {
        .card__body {
            height: 250px;
        }
    }

    .skeletons, .themes {

        .card__thumb {
            height: 300px;


            &:after {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                box-shadow: inset 0 -10px 30px -5px rgba(#333, 0.1);
            }

        }

        .card:hover .card__thumb {
            height: 300px !important;


            img {
                transform: none;
                opacity: 1 !important;
            }
        }


        .card__title {
            padding: 0;

            table {
                td:nth-child(2) {
                    width: auto;
                }
            }
        }

        .card__body {
            padding: 0;
            height: 400px;
        }

        .card__footer {
            background: none;
        }

    }

    .resource-item-links {
        margin-bottom: 1rem;
    }

    .search-wrapper {

        display: flex;

        @include mq($from: tablet, $until: desktop) {
            width: 80%;
        }

        @include mq($from: mobile, $until: tablet) {
            width: 100%;
        }

        .input-wrapper {
            input:hover {
                border: 1px solid $primary-color;
                outline: 0;
            }
        }

        .subcol {
            flex: 1;
            position: relative;
            padding: 0 5px;
        }

        .selectize-control.multi .selectize-input.has-items {
            padding: 0 8px;
        }

        .selectize-input {
            font-size: 25px;
            font-weight: 200;
            // height: 60px;
            border: 1px solid #e1e1e1;
            border-radius: 0;
            box-shadow: none;
            text-align: left;

            &:hover {
                border: 1px solid $primary-color;
                outline: 0;
            }

            &.has-items {
                padding: 10px 8px 7px !important;
            }

            input {
                height: inherit;
                font-size: 25px;
            }
        }

        .selectize-control.multi .selectize-input [data-value] {
            border: 0;
            padding: 0.4rem;
            background-image: none;
            color: $standard-purple;
            background: #eee;
            text-shadow: none;
            box-shadow: none;

            &.active {
                background: $standard-purple;
                color: white;

                .remove {
                    color: lighten($standard-purple, 20);

                    &:hover {
                        color: $white;
                    }
                }
            }
        }

        .selectize-dropdown [data-value] {
            font-size: 25px;
            padding: 1.5rem 2rem;
            text-align: left;

            &.active {
                background: $standard-purple;
                color: white;
            }
        }

        .selectize-control.plugin-remove_button [data-value] .remove {
            border-color: transparent;
            font-size: 25px;
            vertical-align: middle;
            line-height: 40px;
            width: 30px;
            padding: 0;
            color: #999;

            &:hover {
                background: transparent;
                color: #666;
            }
        }

        .selectize-control.plugin-remove_button [data-value] {
            padding-right: 30px !important;
        }
    }

    .select-style {
        border: 1px solid $light-grey;
        border-radius: $global-radius;

        vertical-align: middle;
        display: inline-block;

        overflow: hidden;
        background: darken($white, 2%) url("data:image/png;base64,R0lGODlhDwAUAIABAAAAAP///yH5BAEAAAEALAAAAAAPABQAAAIXjI+py+0Po5wH2HsXzmw//lHiSJZmUAAAOw==") no-repeat 90% 50%;

        select {
            padding: ($leading-margin / 4) ($leading-margin / 4);
            line-height: normal;
            margin: 0 40px 0 0;
            width: 130%;
            border: none;
            box-shadow: none;
            background: transparent;
            background-image: none;
            -webkit-appearance: none;
            -moz-appearance: none;

            &:focus {
                outline: none;
            }
        }
    }

    .gallery {
        img {
            width: 32%;
            margin-right: 1px;
            border-radius: 3px;

            @include mq($from: mobile, $until: desktop) {
                width: 48%;
            }
        }
    }

    #resources-breadcrumbs {
        padding: 10px 1rem 0;

        ul.breadcrumbs {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                display: inline-block;
                font-size: 1.5rem;
                text-transform: uppercase;
                font-weight: 400;
                padding-right: 5px;

                &:after {
                    content: '/';
                    padding-left: 10px;
                }

                &:last-child:after {
                    display: none;
                }
            }
        }
    }
}

.featured, .team-grav {
    overflow: hidden;

    &:before {
        content: '';
        height: 60px;
        right: -30px;
        position: absolute;
        top: -30px;
        width: 60px;
        transform: rotate(45deg);
        z-index: 1;
    }

    &:after {
        color: $white;
        font-family: ForkAwesome;
        line-height: 1;
        position: absolute;
        top: 5px;
        right: 5px;
        width: auto;
        font-size: 18px;
        z-index: 2;
    }
}

.team-grav {
    &:before {
        background: $standard-blue;
    }
    &:after {
        content: '\f00c';
    }
}

.featured {
    &:before {
        background: $standard-tomato;
    }
    &:after {
        content: '\f005';
    }
}

#content {
    .dropdown-priv {
        color: #ce9934;
    }

    // Selectize fix
    .selectize-control {

        &.single .selectize-input, .selectize-dropdown.single {
            border-color: $border-color;
        }

        &.single .selectize-input {
            background: $white;
            box-shadow: none;
        }

        &:hover {
            .selectize-input {
                border-color: $primary-color !important;
            }
        }

        .selectize-input {
            font-size: 20px;
            font-weight: 200;
            padding: 10px;
            border-radius: 0;
            height: 60px;


            input[type="text"] {
                height: auto;

            }
        }

        .selectize-dropdown {
            font-weight: 16px;
            font-weight: 300;

            .active {
                background: $standard-purple;
                color: white;
            }

            .option {
                padding: 5px 10px;
            }
        }
    }
}

#new-resource {
    .selectize-input {
        padding: 15px 8px 14px !important;
    }

    span[style^="background-color:rgb(187,0,0)"] {
        color: white;
    }

    .thank-you {
        margin: 0 -8rem -5rem;
        text-align: center;
        text-transform: uppercase;
        background-image: linear-gradient(90deg, $bright-blue, $bright-purple);
        color: white;
        font-weight: bold;
        padding: 1rem;
        border-radius: 0 0 3px 3px;
    }
}

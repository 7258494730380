@font-face {
    font-family: 'Metropolis';
    src: url('../fonts/metropolis-regular-webfont.woff2') format('woff2'),
    url('../fonts/metropolis-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../fonts/metropolis-bold-webfont.woff2') format('woff2'),
    url('../fonts/metropolis-bold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Public Sans';
    src: url('../fonts/PublicSans-Light.woff2') format('woff2'),
    url('../fonts/PublicSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Public Sans';
    src: url('../fonts/PublicSans-SemiBold.woff2') format('woff2'),
    url('../fonts/PublicSans-SemiBold.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Hack';
    src: url('../fonts/hack-regular-subset.woff2') format('woff2'),
    url('../fonts/hack-regular-subset.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

// @font-face {
//     font-family: 'Novacento Sans Wide';
//     src: url('../fonts/Novecentosanswide-UltraLight-webfont.eot');
//     src: url('../fonts/Novecentosanswide-UltraLight-webfont.eot?#iefix') format('embedded-opentype'),
//          url('../fonts/Novecentosanswide-UltraLight-webfont.woff2') format('woff2'),
//          url('../fonts/Novecentosanswide-UltraLight-webfont.woff') format('woff'),
//          url('../fonts/Novecentosanswide-UltraLight-webfont.ttf') format('truetype'),
//          url('../fonts/Novecentosanswide-UltraLight-webfont.svg#novecento_sans_wideultralight') format('svg');
//     font-style: normal;
// 		font-weight: 200;
// }
// @font-face {
//     font-family: 'Novacento Sans Wide';
//     src: url('../fonts/Novecentosanswide-Normal-webfont.eot');
//     src: url('../fonts/Novecentosanswide-Normal-webfont.eot?#iefix') format('embedded-opentype'),
//          url('../fonts/Novecentosanswide-Normal-webfont.woff2') format('woff2'),
//          url('../fonts/Novecentosanswide-Normal-webfont.woff') format('woff'),
//          url('../fonts/Novecentosanswide-Normal-webfont.ttf') format('truetype'),
//          url('../fonts/Novecentosanswide-Normal-webfont.svg#novecento_sans_widenormal') format('svg');
//     font-weight: 400;
//     font-style: normal;
// }
// @font-face {
//   font-family: 'Work Sans';
//   font-style: normal;
//   font-weight: 200;
//   src:
//     url('../fonts/Work_Sans_200.eot?#iefix') format('embedded-opentype'),
//     url('../fonts/Work_Sans_200.woff') format('woff'),
//     url('../fonts/Work_Sans_200.woff2') format('woff2'),
//     url('../fonts/Work_Sans_200.svg#WorkSans') format('svg'),
//     url('../fonts/Work_Sans_200.ttf') format('truetype');
// }
// @font-face {
// 	font-family: 'Work Sans';
// 	font-style: normal;
// 	font-weight: 300;
// 	src:
//   	url('../fonts/Work_Sans_300.eot?#iefix') format('embedded-opentype'),
// 		url('../fonts/Work_Sans_300.woff') format('woff'),
// 		url('../fonts/Work_Sans_300.woff2') format('woff2'),
// 		url('../fonts/Work_Sans_300.svg#WorkSans') format('svg'),
// 		url('../fonts/Work_Sans_300.ttf') format('truetype');
// }
// @font-face {
// 	font-family: 'Work Sans';
// 	font-style: normal;
// 	font-weight: 500;
// 	src:
// 		url('../fonts/Work_Sans_500.eot?#iefix') format('embedded-opentype'),
// 		url('../fonts/Work_Sans_500.woff') format('woff'),
// 		url('../fonts/Work_Sans_500.woff2') format('woff2'),
// 		url('../fonts/Work_Sans_500.svg#WorkSans') format('svg'),
// 		url('../fonts/Work_Sans_500.ttf') format('truetype');
// }


// RocketTheme Apps Font
@font-face {
    font-family: 'rockettheme-apps';
    src: url('../fonts/rockettheme-apps/rockettheme-apps');
}

;


// RocketTheme Apps Support
i.fa-grav, i.fa-grav-spaceman, i.fa-grav-text, i.fa-grav-full,
i.fa-grav-logo, i.fa-grav-symbol, i.fa-grav-logo-both, i.fa-grav-both,
i.fa-gantry, i.fa-gantry-logo, i.fa-gantry-symbol, i.fa-gantry-logo-both, i.fa-gantry-both {
    font-family: 'rockettheme-apps';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// Grav
.fa-grav-logo:before, .fa-grav-text:before {
    content: "\61";
}

.fa-grav-symbol:before, .fa-grav:before, .fa-grav-spaceman:before {
    content: "\62";
}

.fa-grav-logo-both:before, .fa-grav-both:before, .fa-grav-full:before {
    content: "\66";
}

// Gantry
.fa-gantry-logo:before {
    content: "\64";
}

.fa-gantry:before, .fa-gantry-symbol:before {
    content: "\63";
}

.fa-gantry-logo-both:before, .fa-gantry-both:before {
    content: "\65";
}

.button {
    margin: 4rem 0;
    border-radius: 3px;
    height: 50px;
    line-height: 48px;
    font-size: 1.8rem;
    font-weight: 400;
    padding: 0 30px;

    @include mq($until: phablet) {
        font-size: 1.2rem;
        margin: 2rem 0;
        padding: 0 20px;
    }

    &.button-outline {
        border: 2px solid $white;
        color: $white;
    }

    &.button-outline-dark {
        border: 2px solid $nearly-black;
        color: $nearly-black;

        &.button-fancy::before {
            background: $nearly-black;
        }
    }

    &.button-small {
        margin: 1.5rem 0;
        height: 32px;
        line-height: 30px;
        padding: 0 15px;
    }

    &.button-vsmall {
        margin: 0.5rem 0;
        height: 25px;
        line-height: 23px;
        padding: 0 7px;
        border-radius: 2px;
        font-size: 1.2rem;
    }

    &.button-solid {
        background: $bright-blue;
        color: $white;
    }

    &.button-grav {
        color: $standard-blue;
        border-color: $standard-blue;

        &.active {
            background-color: $standard-blue;
            color: $white;
        }
    }

    &.disabled, [disabled] {
        border-color: #ddd;
        background-color: transparent;
        color: #aaa;
    }
}

.button-fancy {

    display: inline-block;
    transition: border-color 0.4s, color 0.4s;
    border: none;
    background: none;
    color: inherit;
    vertical-align: middle;
    position: relative;
    z-index: 1;

    -webkit-backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
}

.button-fancy:focus {
    outline: none;
}

.button-fancy > span {
    vertical-align: middle;
}

.button-fancy::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: calc(100% + 1px);
    border-radius: 1px;
    background: $standard-purple;
    z-index: -1;
    opacity: 0;
    margin-right: 0px;

    transform: scale3d(0.7, 1, 1);
    transition: transform 0.4s, opacity 0.4s;
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

.button-fancy.button-solid {

    &::before {
        width: 100%;
        border-radius: 3px;
    }
}

.button-fancy,
.button-fancy::before {
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

.button-fancy:hover {
    color: $white;
}

.button-fancy:hover::before {
    opacity: 1;
    transform: translate3d(0, 0, 0);
}

.button-fancy {
    &.disabled, [disabled] {
        border-color: #ddd;
        background-color: transparent;
        color: #aaa;
        pointer-events: none;

        &:hover, &:hover:before {
            color: #aaa;
            transform: none;
        }

        &:before {
            background-color: transparent !important;
        }
    }
}

.button-fancy2 {
    overflow: hidden;
    transition: color 0.3s;
    display: inline-block;
    border: none;
    background: none;
    color: inherit;
    vertical-align: middle;
    position: relative;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
}

.button-fancy2::before,
.button-fancy2::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    bottom: 100%;
    left: 0;
    z-index: -1;
    transition: transform 0.3s;
    transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}

.button-fancy2::before {
    background: $standard-green;
}

.button-fancy2::after {
    background: $standard-blue;
}

.button-fancy2:hover {
    color: $white;
    border-color: $white;
}

.button-fancy2:hover::before,
.button-fancy2:hover::after {
    transform: translate3d(0, 100%, 0);
}

.button-fancy2:hover::after {
    transition-delay: 0.175s;
}

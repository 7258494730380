// Flexbox

.flexbox {
    display: flex;
    flex-flow: row wrap;
    //justify-content: space-between;
    // width: 100%;
    padding: 0;
}

.flexbox-item {
    display: flex;
    flex-flow: column nowrap;

}


// Cards
.card {
    position: relative;
    display: block;
    padding: 1rem;
    transition: all 250ms;
    @include shadow(0);

    &:hover {
        @include shadow(3);
        transform: translateY(-5px);
    }
}

.item-cards {
    @extend .flexbox;
    text-align: left;
    width: auto;
    padding: 0;
    margin: 0 -10px;
}

.item-card {
    @extend .flexbox-item;
    background: $white;
    width: calc(100% / 3 - 20px);
    margin: 10px;

    @include mq($from: tablet, $until: desktop) {
        width: calc(50% - 20px)
    }

    @include mq($from: mobile, $until: tablet) {
        width: 100%;
    }
}

.card__padding {
    padding: 10px;
}

.card__date {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    line-height: 13px;
    padding-top: 10px;
}

.card__date__day {
    display: block;
    font-size: 18px;
    font-weight: 500;
}

.card__date__month {
    display: block;
    margin-top: 2px;
    font-size: 11px;
    text-transform: uppercase;
}

.card__thumb {
    position: relative;
    height: 235px;
    overflow: hidden;
    text-align: center;
    transition: height 0.3s;

    img {
        opacity: 1;
        transition: opacity 0.3s, transform 0.3s;
        transform: scale(1);
    }
}

.card__header {
    position: relative;
    top: 50%;
    display: inline-block;
    margin: 0 auto;
    opacity: 1;
    color: $white;
    font-size: 3.6rem;
    transform: scale(1.0) translateY(-50%);
    transition: opacity 0.3s, transform 0.3s;
}

.card:hover .card__header {
    opacity: 0.6;

    transform: scale(1.2) translateY(-50%);
}

.card:hover .card__thumb {
    height: 70px !important;

    img {
        opacity: 0.6;
        transform: scale(1.2);
    }
}

.card__body {
    overflow: hidden;
    position: relative;
    padding: 10px;
    height: 185px;
    transition: height 0.3s;
}

.card:hover .card__body {
    height: 330px;
}

.card:hover .card__preview {
    opacity: 1;
    transform: scale(1.2);
}

.card__tags {
    display: block;
    float: left;
    margin-top: -25px;
    height: 25px;
    font-size: 12px;
    line-height: 25px;
}

.card__tags {

    span {
        display: inline-block;
        color: #fff;
        text-decoration: none;
        // text-transform: uppercase;
        padding: 0 10px;
    }

}

.card__title {
    padding: 5px 0 10px 0;
    margin: 0;


    @extend .subheader;

    a {
        color: darken($font-color, 10%);
    }
}

.card__description {
    font-size: 16px;

    .notices {
        margin-top: 0;
    }
}

.card__footer {
    position: absolute;
    background-image: linear-gradient(to bottom, rgba($white, 0) 0%, rgba($white, 1) 55%, rgba($white, 1) 100%);
    color: #a3a9ab;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 40px 20px 10px;
    min-height: 15px;
    font-size: 1.3rem;
    // text-transform: uppercase;
}

.card__author {
    display: block;
    float: left;
    line-height: 30px;

    img {
        max-width: 30px;
        max-height: 30px;
        border-radius: 100%;
        vertical-align: middle;
        margin: 0 5px 0;
    }
}

.card__time {
    display: block;
    float: right;
    right: 0;
    line-height: 30px;
}

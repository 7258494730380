/* Common style */
figure.hover-effect {
    position: relative;
    float: left;
    overflow: hidden;
    margin: 0;
    height: auto;
    background: $dark-bg;
    text-align: center;
    cursor: pointer;

    h2, p {
        margin: 0;
    }

    p {
        letter-spacing: 1px;
        font-size: 68.5%;
    }

    img {
        position: relative;
        display: block;
        max-width: 100%;
        opacity: 0.8;
    }

    figcaption, figcaption > a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    figcaption {
        padding: 2em;
        color: #fff;
        text-transform: uppercase;
        font-size: 1.25em;
        backface-visibility: hidden;

        &::before,
        &::after {
            pointer-events: none;
        }

        > a {
            z-index: 1000;
            text-indent: 200%;
            white-space: nowrap;
            font-size: 0;
            opacity: 0;
        }
    }
}

/*---------------*/
/***** Duke *****/
/*---------------*/

figure.effect-duke {
    background-image: linear-gradient(-45deg, $standard-purple 0%, $standard-blue 100%);

    img, p {
        transition: opacity 0.35s, transform 0.35s;
    }

    h2 {
        transition: transform 0.35s;
        transform: scale3d(0.8, 0.8, 1);
        transform-origin: 50% 100%;
        opacity: 0;
    }

    p {
        position: absolute;
        bottom: 0;
        left: 0;
        margin: 20px;
        padding: 30px;
        border: 1px solid $white;
        color: $white;
        text-transform: none;
        font-size: 90%;
        opacity: 0;
        transform: scale3d(0.8, 0.8, 1);
        transform-origin: 50% -100%;
    }

    &:hover {
        img {
            opacity: 0.1;
            transform: scale3d(2, 2, 1);
        }

        h2, p {
            opacity: 1;
            transform: scale3d(1, 1, 1);
        }
    }
}

.changelog {
    text-align: left;

    h1, .remodal-close {
        text-align: center;
    }

    code {
        white-space: pre-wrap;
    }

    .remodal-confirm {
        float: right;
    }

    .changelog-overflow {
        max-height: 500px;
        overflow: auto;
        margin-bottom: 1em;
        border: 1px solid #ddd;
        border-radius: 3px;
        background: white;
        padding: 1em;
    }
}

.remodal {
    h1 {
        font-size: 3rem;
        margin: 1rem 0;
    }
}

ul.gallery {
    padding: .5vw;
    font-size: 0;
    display: -ms-flexbox;
    -ms-flex-wrap: wrap;
    -ms-flex-direction: column;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    display: -webkit-box;
    display: flex;

    li {
        margin: 10px;

        img {
            height: auto;
            max-width: 220px;
        }
    }
}

@media screen and (max-width: 400px) {
    ul.gallery li {
        margin: 0;
    }
    ul.gallery {
        padding: 0;
    }
}
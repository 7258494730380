@mixin vertical-align {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

@mixin horizontal-align {
    left: 50%;
    transform: translateX(-50%);
}

@mixin shadow($level: 1) {
    @if $level == 0 {
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.095), 0 1px 2px rgba(0, 0, 0, 0.1);
    } @else if $level == 1 {
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.1);
    } @else if $level == 2 {
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.1);
    } @else if $level == 3 {
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.1);
    } @else if $level == 4 {
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.1);
    } @else if $level == 5 {
        box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.1);
    }
}

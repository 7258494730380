// homepage variables
$light-overlap: 20rem;
$offwhite: rgba($white, 0.8);

#showcase {
    background: $dark-bg;
    height: $header-height;
    position: relative;
    overflow: hidden;

    #stars-overlay {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: $header-height;
        background-image: linear-gradient(rgba($dark-bg, 0), rgba($dark-bg, 1));
    }

    #moon {
        position: absolute;
        left: 10%;
        top: 300px;
        width: 150px;
    }

    #gas-planet {
        position: absolute;
        right: -75px;
        top: 250px;
        width: 520px;
    }

    #blue-planet-wrapper {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
    }

    #blue-planet {
        vertical-align: bottom;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        min-width: 900px;
        max-width: 1200px;
        height: 100%;
    }

    #blue-planet-overlay {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 300px;
        background-image: linear-gradient(rgba($dark-bg, 0), rgba($dark-bg, 1));
    }

    @include mq($from: tablet, $until: desktop) {
        #moon {
            width: 125px;
            left: 5%;
        }
        #gas-planet {
            width: 450px;
            right: -125px;
        }
    }
    @include mq($from: mobile, $until: tablet) {
        #moon {
            width: 100px;
            left: 5%;
        }
        #gas-planet {
            width: 400px;
            right: -150px;
        }
    }
}

#up-arrow {
    top: 0;
}

#down-arrow {
    bottom: 0;
}

#down-arrow,
#up-arrow {
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    font-size: 5rem;

    a {
        position: relative;
        z-index: 1;
        color: $offwhite;
    }
}

#showcase-content {
    position: absolute;
    top: 50px;
    width: 100%;
    text-align: center;
    color: $white;

    h1 {
        color: rgba($white, 0.7);
        font-size: 7rem;
        // text-transform: uppercase;
        strong {
            font-weight: inherit;
            color: $white;
        }

        margin: 3rem 0 0;
    }

    h5 {
        color: $offwhite;
        font-weight: 400;
        margin: 0;
    }

    .version {
        // text-transform: uppercase;
        font-weight: 300;
        font-size: 1.5rem;
        color: rgba(lighten($bright-blue, 40%), 0.7);

        strong {
            color: rgba(lighten($bright-blue, 40%), 0.9);
        }
    }

    .downloads {
        position: absolute;
        top: 180px;
        right: 0;
        left: 0;
    }

    @include mq($from: tablet, $until: desktop) {
        h1 {
            font-size: 5rem;
            margin: 3rem 0 2rem;
        }
        h5 {
            font-size: 2.2rem;
        }
    }
    @include mq($from: mobile, $until: tablet) {
        h1 {
            font-size: 3rem;
            margin: 0rem 0 1.5rem;
        }
        h5 {
            font-size: 1.8rem;
        }
    }
}

.banner-enabled {
    #onpage-nav {
        top: $header-height + 44px;
    }
}

#onpage-nav {
    background: $dark-bg;
    position: absolute;
    top: $header-height;
    z-index: 1000;
    height: 69px;
    left: 0;
    right: 0;
    border-bottom: 1px solid lighten($dark-bg, 10%);
    box-shadow: 0px 2px 10px 4px rgba(0, 0, 0, 0.2);

    .container {
        @include vertical-align();
        text-align: center;
    }
}

#page-nav {
    display: inline-block;
    margin: 0;
    padding: 0;

    li {
        margin: 0;
        padding: 0;
        display: inline-block;
    }
}

#onpage-nav.page-nav-scrolled {
    position: fixed;
    width: 100%;
    top: 0;
}

#body {
    padding-top: 70px;
    background: $dark-bg;
}

.top-divider {
    height: 5px;

    .block {
        width: 33%;
        float: left;
        height: 5px;

        &:last-child {
            width: 34%;
        }
    }
}

// Default for modular section
.modular-section {
    position: relative;
    background: $dark-bg;
    color: $light-purple;
    text-align: center;
    padding-top: 1rem;

    &.modular-light {
        background: $light-bg;
        color: $font-color;

        .padding {
            h1 {
                color: $nearly-black;
            }
        }
    }

    h1 {
        color: $white;
        font-weight: 600;
    }

    ul {
        margin: 0;
        padding: 0;
    }

    @include mq($from: tablet, $until: desktop) {
        h1 {
            font-size: 5rem;
            margin: 3rem 0 2rem;
        }
    }
    @include mq($from: mobile, $until: tablet) {
        h1 {
            font-size: 3rem;
            margin: 0rem 0 1.5rem;
        }
    }
}

#modular-choose {
    padding-top: 1rem;

    #cms-critic {
        margin-bottom: 2rem;

        .row {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .col {
            p {
                margin: 0;
                font-size: 3rem;
                line-height: 1.2;
                color: rgba(255, 255, 255, 0.7);

                @include mq($from: tablet, $until: desktop) {
                    font-size: 2.5rem;
                }
                @include mq($from: mobile, $until: tablet) {
                    font-size: 2rem;
                }
            }

            &.left, &.right {
                flex: 1;
            }

            &.image {
                flex: 2;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .col {
        width: 33.333%;
        float: left;

        &:first-child .block {
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
        }

        &:last-child .block {
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
        }

        p {
            margin: 6rem 3rem 9rem;
            font-weight: 300;
        }
    }

    .block {
        height: 250px;

        img {
            max-width: 12rem;
            max-height: 12rem;
        }

        span {
            margin-top: 1rem;
            display: block;
            font-size: 3rem;
        }

        @include mq($from: tablet, $until: desktop) {
            height: 200px;
            img {
                max-width: 9rem;
                max-height: 9rem;
            }
            span {
                font-size: 2.5rem;
            }
        }
        @include mq($from: mobile, $until: tablet) {
            height: 150px;
            img {
                max-width: 7rem;
                max-height: 7rem;
            }
            span {
                font-size: 1.8rem;
            }
            & + p {
                margin: 4rem 1rem 4rem;
            }
        }
    }
}

#modular-easy-to-use {
    padding-bottom: 2rem;

    #overlap {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: $light-overlap;
        background: $dark-bg;
    }

    .container {
        background: $white;
        box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.05);
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    .carousel-padding {
        padding: 0rem 0rem 5rem;
    }

    .owl-theme .owl-item p {
        bottom: 0;
    }
}

#modular-developers {
    box-shadow: inset 0px -8px 8px -5px rgba(0, 0, 0, 0.05);

    img {
        max-width: 200px;
    }
}

#modular-features {
    background: $white;

    .feature-box {
        border-bottom: 1px solid #eee;
        border-right: 1px solid #eee;
        margin: 0;
        padding: 2rem 4rem 2rem;
        width: calc(100% / 3);

        h4 {
            font-family: $default-font-family;
            font-size: 2.5rem;
            color: $nearly-black;
        }

        .feature-box-image {
            img {
                max-width: 60%;
                border-radius: 100%;
                margin-bottom: 3rem;
            }
        }

        &:nth-child(3n+0) {
            border-right: 0;
        }

        &:nth-child(n+4) {
            border-bottom: 0;
        }
    }

    .feature-box img {
        order: -1;
    }

    .feature-box p {
        flex: 1 0;
    }

    @include mq($from: tablet, $until: desktop) {
        .feature-box {
            padding: 2rem;

            h4 {
                font-size: 2.2rem
            }
        }
    }
    @include mq($from: mobile, $until: tablet) {
        .feature-box {
            padding: 0.5rem;

            h4 {
                font-size: 1.8rem
            }
        }
    }
}

#modular-pros {
    box-shadow: inset 0px 8px 8px -5px rgba(0, 0, 0, 0.05);
    background: $light-bg;
    color: $font-color;

    .padding {
        h1 {
            color: $nearly-black;
        }
    }

    img {
        max-width: 500px;
    }

    ul {
        display: inline-block;
        margin: 3rem 0;

        li {
            width: 50%;
            display: inline-block;
            float: left;
            text-align: left;
            @include mq($from: mobile, $until: tablet) {
                width: 100%;
            }
        }
    }
}

.become-backer {
    margin: 8rem auto 2rem;
    max-width: 550px;
}

#modular-community {
    color: rgba($white, 0.85);
    background: $standard-blue;

    a:not(.button) {
        color: $white;
        font-weight: 300;
    }

    h1 {
        color: $white;
    }

    ul {
        list-style: none;

        li {
            display: inline-block;
            margin: 3rem 3rem 0;
            @include mq($from: mobile, $until: tablet) {
                margin: 3rem 1.5rem 0;
            }

            i {
                font-size: 4rem;
            }

            span {
                display: block;
            }
        }
    }
}

#modular-limitless {
    .top-divider {
        margin-top: 5rem;
    }

    .carousel-padding {
        padding: 0 4rem 3rem;
    }

    figure {
        margin: 0 0 3.5rem 0;
        text-align: center;
    }

    .owl-theme .owl-item p {
        margin-top: 1rem;
        color: rgba($white, 0.8);
    }

    .owl-carousel .owl-item img {
        display: inline-block;
        width: 75%;
        max-width: 300px;
    }

    .owl-nav {
        top: 20%;

        .owl-prev,
        .owl-next {
            padding: 0;
            margin: 0;
            color: rgba($standard-purple, 0.4);

            &:hover {
                color: $standard-blue;
            }
        }

        .owl-prev {
            left: -4.5rem;
        }

        .owl-next {
            right: -4.5rem;
        }
    }

    @include mq($from: tablet, $until: desktop) {
        .carousel-padding {
            padding: 0 3rem 2rem;
        }
    }
    @include mq($from: mobile, $until: tablet) {
        .carousel-padding {
            padding: 0 2rem 2rem;
        }
    }
}

#modular-built-with {
    padding-bottom: 5rem;

    .carousel-padding {
        padding: 0 4rem 5rem;

        h2 {
            font-size: 3rem;
        }

        p {
            font-size: 1.7rem;
            color: $white;
            bottom: 5px;
        }

        .owl-item {
            height: 100%;
        }

        .owl-nav {
            top: 25%;

            .owl-prev,
            .owl-next {
                padding: 0;
                margin: 0;
                color: rgba($standard-purple, 0.4);

                &:hover {
                    color: $standard-blue;
                }
            }

            .owl-prev {
                left: -4.5rem;
            }

            .owl-next {
                right: -4.5rem;
            }
        }
    }

    @include mq($from: phablet, $until: tablet) {
        figcaption {
            padding: 1em;
        }
    }
    @include mq($from: mobile, $until: phablet) {
        figcaption {
            padding: 0;
        }
    }
}

#modular-people-saying {
    border-top: 1px solid lighten($dark-bg, 10%);

    .tweet {
        text-align: center;
        margin-top: 3rem;
        width: auto;

        img {
            width: 70px;
            height: 70px;
            margin: 0 auto;
            border-radius: 100%;
        }

        .comment {
            font-size: 1.5rem;
            margin: 0 1rem;
        }

        h5 {
            font-family: $default-font-family;
            font-size: 18px;
            color: $white;
            margin: 1rem 0 0;
        }

        a {
            font-size: 1.5rem;
            color: $light-purple;

            &:hover {
                color: lighten($light-purple, 10%);
            }
        }

        hr {
            padding: 1px;
            width: 50px;
            margin: 2rem auto;
            background: $standard-blue;
            border: 0;
        }

        p {
            margin: 0 2rem;
            font-size: 1.5rem;
        }
    }

    @include mq($from: mobile, $until: tablet) {
        .tweet {
            p {
                margin: 0 0.5rem;
            }
        }
    }
}

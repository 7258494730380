.header-overlay {
    header {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background: transparent;
    }
}

header {

    ul {
        margin: 0;
        padding: 0;

        li {
            margin: 0;
            padding: 0;
            display: inline-block;
        }
    }

    a {
        color: $standard-blue;
    }

    .row {
        height: $toolbar-height;
        position: relative;
    }

    #grav-logo {
        width: 140px;
        float: left;

        path {
            fill: $white !important;
        }
    }

    #menu {
        position: relative;
        text-align: center;
        margin: 0 156px;
        top: 34px;


        @include mq($from: mobile, $until: desktop) {
            display: none;
        }
    }

    .social-wrapper {
        position: absolute;
        right: 0;
        top: 29px;
        font-size: 2.4rem;

        @include mq($from: mobile, $until: desktop) {
            display: none;
        }

        li {
            padding: 0;
            position: relative;
            margin-left: -5px;

            @include mq($from: wide) {
                padding: 0 0.5rem;
            }
        }

        i {
            color: $white;
        }
    }
}

.social-wrapper {
    li {
        position: relative;
    }

    .count {
        color: rgba(255, 255, 255, 0.5);

        position: absolute;
        top: 37px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 12px;
    }
}

#overlay-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;

}

.overlay-nav {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin: 100px;
    z-index: 0;
    display: block;
    opacity: 0;

    transition: opacity 0.5s ease;

    ul.navigation {
        font-size: 4rem;
        list-style: none;
        line-height: 1.8;
    }

    .social-buttons {
        transform-origin: 3% 50%;
        transform: scale(3);
        margin-top: 50px;
        padding-left: 23px;

        li {
            display: inline-block;
        }

    }

    @include mq($from: phablet, $until: desktop) {
        margin: 40px 75px;
        ul.navigation {
            font-size: 3rem;
        }
        .social-buttons {
            transform: scale(2.3);
        }
    }

    @include mq($from: mobile, $until: phablet) {
        margin: 30px 25px;
        ul.navigation {
            font-size: 2rem;
        }
        .social-buttons {
            transform: scale(1.3);
        }
    }
}

.banner-enabled {
    .nav-toggle {
        position: absolute;
        top: 60px;
        right: 40px;

        @include mq($from: mobile, $until: phablet) {
            position: absolute;
            top: 60px;
            right: 15px;
        }
    }
}

// Hamburger
.nav-toggle {
    width: 60px;
    height: 60px;
    z-index: 1001;

    border-radius: 50%;
    //background-color: #4A75E6;

    position: absolute;
    top: 20px;
    right: 40px;

    @include mq($from: mobile, $until: phablet) {
        position: absolute;
        top: 20px;
        right: 15px;
    }

    cursor: pointer;

    box-shadow: 0 3px 0 lighten(black, 20%);

    transition-duration: 0.66s;
    transition-timing-function: swing;


    display: none;

    @include mq($from: mobile, $until: desktop) {
        display: block;
    }


    &:before {
        width: 60px;
        height: 60px;

        border-radius: 50%;
        background-color: $standard-green;
        content: "";

        position: absolute;
        top: 50%;
        left: 50%;

        transform: translateX(-50%) translateY(-50%);
        transition-duration: 0.66s;
        transition-timing-function: swing;
    }

    .icon {
        width: 30px;
        height: 4px;

        background-color: white;
        border-radius: 2px;

        position: absolute;
        top: 28px;
        left: 15px;

        transition-duration: 0.5s;
        transition-timing-function: swing;

        &:before,
        &:after {
            width: 30px;
            height: 4px;
            background-color: white;
            border-radius: 2px;

            content: "";
            position: absolute;

            left: 0;

            transition-duration: 0.5s;
            transition-timing-function: swing;
        }

        &:before {
            top: -9px;
        }

        &:after {
            top: 9px;
        }
    }
}

body {

    &.nav-open {

        .overlay-nav {
            opacity: 1;
            z-index: 1002;
            width: 70vw;
            transition: opacity 1s ease;

            @include mq($from: desktop) {
                z-index: 0;
            }
        }

        .nav {
            transform: translateY(0);
        }

        .nav-toggle {
            box-shadow: none;

            &:before {
                width: 6000px;
                height: 6000px;
                background-color: darken($standard-purple, 20%);
            }

            .icon {
                width: 0;

                &:before {
                    transform: translateY(9px) rotate(45deg);
                }

                &:after {
                    transform: translateY(-9px) rotate(-45deg);
                }
            }
        }
    }
}

.muut {
    .m-page {
        width: 80%;

        a {
            color: $link-color;
            font-weight: 300;

            &:hover {
                color: darken($link-color, 10%);
            }
        }
    }

    &.m-tablet .m-subnavi {
        background-color: $white;

        .m-login, .m-myname, .m-down {
            color: $font-color;
        }
    }


    &.md-boxed {
        .m-moot {
            margin-bottom: 0.75rem;
        }

        .m-moot, .m-input-wrap {
            background-color: rgba(0, 0, 0, 0.03);
            border-radius: 2px;
        }

        .m-seed {
            padding: 1rem 1rem 0;
        }
    }

    .m-closed .m-show-all {
        color: #fff;
        font-size: 100%;

        &:hover {
            color: #fff;
        }
    }

    &.moot {
        color: $font-color;
    }

    .m-body {
        p {
            font-size: 100%;

        }

        .m-empty {
            margin: 1rem 0;
            padding: 0;
        }
    }

    .m-post samp {
        border-radius: 2px;
        padding: .2rem .5rem;
        margin: 0 .2rem;
        white-space: nowrap;
        color: darken($font-color, 10%);
        background: lighten(#FDF7E3, 2%);
        border: 1px solid darken(#FDF7E3, 5%);
        font-size: 85%;
        font-family: "Consolas", menlo, monospace;
    }

    .m-syntax {
        margin: 3rem 0;
        border-radius: 2px;
        padding: 1rem;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-left: .5em solid rgba(0, 0, 0, 0.1);
        background: #fff;
        font-size: 85%;
        font-family: "Consolas", menlo, monospace;
    }

    .m-meta .m-mod-badge {
        font-size: 80%;
        line-height: 1;
        background-color: $link-color;
        padding: 0.1rem 0.2rem;
    }

    .m-header {
        background: none;
        border-bottom: 1px solid $light-grey;
    }

    .m-hilite, .m-newcount, .m-nav-online em, .m-h3.m-users-title em {
        height: auto;
        background: $standard-tomato;
    }

    .m-navi {
        margin-bottom: -1px;

        a {
            color: $link-color;
            border-bottom: 1px solid transparent;

            &.m-selected {
                border-bottom: 1px solid $font-color;
                color: $font-color;

                &:hover {
                    border-bottom: 1px solid $font-color;
                }
            }

            &:hover {
                box-shadow: none;
                border-bottom: 1px solid $link-color;
            }
        }
    }

    #m-search {
        input {
            border-radius: 2px;
            border-color: $light-grey;
        }
    }

    .m-sidebar {
        border-left: 1px solid $light-grey;
        width: 20%;

        .m-h3 {
            color: $font-color;
        }
    }

    .m-links a {
        color: $link-color;
        border-radius: 0;
        font-weight: 300;

        &:hover {
            color: darken($link-color, 20%);
            background: none;
        }

        &.m-selected {
            background: none;
            color: $font-color;
        }
    }

    &.moot button, .m-button {
        background-color: $link-color;
        border-radius: 4px;
    }

    .m-input-wrap {
        height: 2.5em;
    }

    .m-focused {
        .m-input-wrap, .m-postinput {
            border-color: $link-color;
            box-shadow: none;
        }
    }

    .m-select .m-selected {
        background: $link-color;
    }

    .m-select .m-hovered, .m-select .m-selected {
        border-radius: 4px;
    }

    .m-expanded .m-seed .m-body-wrap, .m-expanded .m-reply {
        border-left: 1px solid $light-grey;
    }

    .m-hilite, .m-newcount, .m-nav-online em, .m-h3.m-users-title em {
        font-family: $title-font-family;
        font-weight: bold;
    }

    #mt-start.m-active {
        display: none !important;
    }

    &.moot .m-more-moots {
        &:hover {
            background-image: none;
        }

        &:after {
            color: $link-color;
        }
    }
}

.muut-messaging.m-loaded {
    right: 115px;
    letter-spacing: -0.02rem;
    font-weight: 400;
    font-size: 1.2rem;
    font-family: "Work Sans", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding: 0.4rem 0.6rem 0.2rem;
    border-radius: 3px 3px 0 0;
    color: #fff;
    background-color: rgba($primary-color, 0.8);
    border: 1px solid $primary-color;
    border-bottom: 0;
    text-transform: uppercase;
    z-index: 999;
}

footer {
    position: relative;
    border-top: 5px solid $standard-blue;
    background: $dark-bg;
    color: $light-purple;
    font-size: 1.5rem;
    text-align: center;
    font-weight: 300;

    .padding {
        padding: 5rem 0;
    }

    p {
        margin-bottom: 1rem;

        i.fa, strong {
            color: $white;
            font-weight: inherit;
        }
    }

    ul {
        margin: 0;
    }

    .row {
        margin-bottom: 3rem;
    }

    /* Mailchimp form */
    #mc_embed_signup ::-webkit-input-placeholder {
        color: #977cad;
    }

    #mc_embed_signup ::-moz-placeholder {
        color: #977cad;
        opacity: 1;
    }

    #mc_embed_signup ::-ms-placeholder {
        color: #977cad;
    }

    #mc_embed_signup ::placeholder {
        color: #977cad;
    }

    #mc_embed_signup form {
        text-align: center;
        padding: 10px 0 10px 0;
    }

    .mc-field-group {
        display: inline-block;
    }

    /* positions input field horizontally */
    #mc_embed_signup input.email {
        font-family: "Open Sans", "Helvetica Neue", Arial, Helvetica, Verdana, sans-serif;
        font-size: 15px;
        border: 1px solid rgba(#fff, 0.2);
        border-radius: 3px;
        background-color: rgba(#fff, 0.1);
        color: #eee;
        box-sizing: border-box;
        height: 32px;
        padding: 0px 0.4em;
        display: inline-block;
        margin: 0;
        width: 350px;
        vertical-align: top;

        &.mce_inline_error {
            border: 1px solid $link-color;
        }
    }

    #mc_embed_signup label {
        display: block;
        font-size: 16px;
        padding-bottom: 10px;
        font-weight: bold;
    }

    #mc_embed_signup .clear {
        display: inline-block;
        vertical-align: top;
    }

    /* positions button horizontally in line with input */
    #mc_embed_signup .button {
        font-size: 13px;
        border: none;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        letter-spacing: .03em;
        color: #fff;
        background-color: #aaa;
        box-sizing: border-box;
        height: 32px;
        line-height: 32px;
        padding: 0 18px;
        display: inline-block;
        margin: 0;
        transition: all 0.23s ease-in-out 0s;
    }

    #mc_embed_signup .button:hover {
        background-color: #777;
        cursor: pointer;
    }

    #mc_embed_signup div#mce-responses {
        float: left;
        top: -1.4em;
        padding: 0em .5em 0em .5em;
        overflow: hidden;
        width: 90%;
        margin: 0 5%;
        clear: both;
    }

    #mc_embed_signup div.response {
        margin: 1em 0;
        padding: 1em .5em .5em 0;
        font-weight: bold;
        top: -1.5em;
        z-index: 1;
    }

    #mc_embed_signup #mce-responses div.response {
        display: none;
        color: rgba(#fff, 0.7);
        float: none;
        margin: 1rem auto;
        border-radius: 3px;
        padding: 10px;

        a {
            color: #fff;
        }
    }

    #mc_embed_signup #mce-error-response {
        background: #FF4136;
    }

    #mc_embed_signup #mce-success-response {
        background: #2ECC40;
    }

    #mc_embed_signup div.mce_inline_error {
        display: none !important;
    }

    #mc_embed_signup label.error {
        display: block;
        float: none;
        width: auto;
        margin-left: 1.05em;
        text-align: left;
        padding: .5em 0;
    }

    @media (max-width: 768px) {
        #mc_embed_signup input.email {
            width: 100%;
            margin-bottom: 5px;
        }
        #mc_embed_signup .clear {
            display: block;
            width: 100%
        }
        #mc_embed_signup .button {
            width: 100%;
            margin: 0;
        }
    }

    #mc_embed_signup {
        clear: left;
        font: 14px Helvetica, Arial, sans-serif;
        width: 100%;
    }

    #mc_embed_signup input.email {
        vertical-align: baseline;
        height: 35px;
    }

    #mc_embed_signup .button {
        height: 35px;
        line-height: 38px;
        background-color: #0ec1f6;
    }

    @include mq($from: mobile, $until: tablet) {
        .columns.right {
            text-align: left;
        }
    }
}

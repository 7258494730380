$login-bg: #fff;

.grav-login-wrapper {
    background-image: url(../images/login-bg.jpg);
    background-size: 100% 100%;
    background-attachment: fixed;
}

#grav-login {
    text-align: center;
    max-width: 500px;
    margin: 6rem auto;
    border-radius: 10px;
    box-shadow: 0px 0px 100px rgba(#000, 0.3);

    form {
        margin: 5rem 3rem 0;

        @include mq($from: mobile, $until: tablet) {
            margin: 3rem 1rem 0;
        }
    }

    .login-header {
        padding: 10rem 6rem;
        border-radius: 10px;
        background-image: url(../images/login-bg-blur.jpg);
        background-size: 100% 100%;
        background-attachment: fixed;

        @include mq($from: mobile, $until: tablet) {
            padding: 5rem 1rem;
        }
    }

    button {
        width: 100%;
        border: 0;
        margin: 2rem 0 0;
        height: inherit;
        padding: 10px;
        border-radius: 3px;
        transition: background-color 0.4s;

        &.github {
            background-color: $standard-green;

            &:hover {
                background-color: darken($standard-green, 5%);
            }
        }

        svg {
            fill: #fff;
            width: 24px;
            height: 24px;
            vertical-align: middle;
        }

        span {
            vertical-align: middle;
            color: #fff;
            font-size: 16px;
            text-transform: none;
            font-weight: 300;

            @include mq($from: mobile, $until: tablet) {
                font-size: 13px;
            }

        }
    }

    p, label, span {
        color: #eee;
        margin-bottom: 0;
        text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
    }

    label[for=username], label[for=password] {
        display: none;
    }

    .rememberme {
        label {
            display: inline-block;
            font-weight: 300;
        }
    }

    input[type='submit'] {
        display: none;
    }

    ul.oauth-provider {
        list-style: none;
        padding: 0;
        margin: 0;
    }


    h2 {
        color: #fff;
        margin: 1rem 0 2rem;
        text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
    }
}

#account-info {

    display: flex;
    margin: 0 auto;
    background: #f9f9f9;
    padding: 20px;
    border-radius: 6px;

    .account-avatar {
        flex: 0 0 150px;

        img {
            border-radius: 15px;
            max-width: 120px;
        }
    }

    .account-details {
        flex: 1;

        .button {
            margin: 10px 0;
            padding: 0px 20px;
            height: 32px;
            line-height: 30px;
        }
    }

    .account-item {

        display: flex;

        .account-key {
            flex: 1;
        }

        .account-data {
            flex: 3;
            color: darken($font-color, 20%);
            font-weight: 400;

        }
    }
}

#account-resources {
    h2 {
        margin: 3rem 0;

        .button {
            margin: 1rem 0 0;
        }
    }

    th, td {
        padding: 5px 10px;
        font-size: 16px;
    }

    table {
        width: 100%;

        th {
            font-weight: 400;
        }

        td {
            line-height: 1.1;
        }

        td:first-child {
            text-align: center;
            padding-left: 15px;
        }

        .resource-title {
            font-size: 18px;
        }

        .resource-owner {
            margin-top: 4px;
            font-size: 1.4rem;

            a {
                color: #999;
            }
        }

        .resource-version {
            line-height: 1.5;
            font-size: 1.5rem;
        }

        .resource-likes {
            font-size: 14px;

            svg {
                width: 16px;
            }
        }

        .resource-views, .resource-downloads {
            text-align: right
        }

    }

}